
const PricingCrmDetail = () => {
  return (
    <div className="w-full">
          <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
            <thead>
                <tr>
                    <th className="text-left bg-[#D6E4FF] border-b border-gray-300 px-4 py-3 font-medium">Tính năng</th>
                    <th className="bg-[#D6E4FF] border-b border-gray-300 px-4 py-3 font-medium text-center">Basic<br/><span className="text-sm font-normal">50,000 VND</span></th>
                    <th className="bg-[#D6E4FF] border-b border-gray-300 px-4 py-3 font-medium text-center">Standard<br/><span className="text-sm font-normal">50,000 VND</span></th>
                    <th className="bg-[#D6E4FF] border-b border-gray-300 px-4 py-3 font-medium text-center">Advanced<br/><span className="text-sm font-normal">50,000 VND</span></th>
                </tr>
                <tr>
                    <th className="  border-b border-gray-300 px-4 py-2 font-medium"></th>
                    <th className=" border-b border-gray-300 px-4 py-2 font-medium text-center"><span className="text-sm font-normal">50,000 VND</span></th>
                    <th className=" border-b border-gray-300 px-4 py-2 font-medium text-center"><span className="text-sm font-normal">50,000 VND</span></th>
                    <th className=" border-b border-gray-300 px-4 py-2 font-medium text-center"><span className="text-sm font-normal">50,000 VND</span></th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">CRM Bán hàng</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Quản lý khách hàng</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tạo view và bộ lọc liên hệ theo mục đích sử dụng</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tự động chia liên hệ cho nhân viên</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Lưu trữ lịch sử chăm sóc</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Thiết lập quy trình bán hàng</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Thiết lập báo cáo thông minh</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Giao và giám sát mục tiêu doanh số</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Quản lý cơ hội bán hàng</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Báo cáo bán hàng</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Cấu hình dữ liệu khách hàng, công ty, sản phẩm</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Phân quyền nhân viên, nhóm nhân viên</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Nhập, xuất dữ liệu liên hệ</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Quản lý Ticket</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tạo động sách liên hệ trình/động phân loại khách hàng</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tự động đồng bộ dữ liệu khách hàng từ landing page hoặc từ các kênh</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">Chat đa kênh</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chat qua Facebook Page</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chat qua Zalo OA</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chat qua Zalo cá nhân</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chat qua Telegram</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chat qua Instagram</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chat qua Viber</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chat qua Live chat</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">Tổng đài ảo</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tổng đài ảo inbound, outbound</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Định tuyến cuộc gọi / Tự động phân bổ cuộc gọi vào theo nhiều tiêu chí</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Kết nối tích hợp đầu số hotline 1900, 1800 và đầu số cố định, di động</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Ghi âm cuộc gọi thoại</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Quản lý chi tiết lịch sử cuộc gọi vào, cuộc gọi ra và cuộc gọi bị nhỡ</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">AI phân tích nội dung cuộc gọi</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">Chiến dịch nhắn tin</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tích hợp gửi tin nhắn ZNS, SMS</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Gửi tin nhắn hàng loạt</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tích hợp chiến dịch gửi tin nhắn với quy trình tự động hoá</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chiến dịch Email Marketing</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Quản lý danh sách gửi email</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tích hợp gửi email với quy trình tự động hoá</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Báo cáo hiệu quả Email marketing</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">Chiến dịch Telesales</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chế độ quay số manual</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chế độ quay số preview</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chế độ quay số progressive</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chế độ quay số hybrid</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Quản lý nhân viên phụ trách chiến dịch</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Xây dựng kịch bản gọi điện</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Nhập khách hàng từ file excel, danh sách liên hệ, tích hợp từ CRM</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">Callbot AI Ucall</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Thiết lập kịch bản gọi điện</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Cá nhân hóa cuộc gọi</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Cảm ứng giọng nói - Chờ khách hàng nói hết</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Callbot nhận diện giới tính</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Cài đặt khung giờ gọi</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Gọi lại khi máy bận, không nhấc máy, voicemail</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chuyển máy sang nhân viên</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">AI tự động dẫn nhân cuộc gọi</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Speech-to-text chuyển ghi âm cuộc gọi thành văn bản</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Báo cáo chiến dịch thông minh</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">Tự động hóa UFlow</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Công cụ kéo thả xây dựng quy trình tự động hóa</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Thiết lập luồng tự động hóa tùy biến thông minh</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tích hợp phần mềm bên thứ 3</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td colspan="4" className="bg-gray-100 border-b border-gray-300 px-4 py-3 font-medium text-left">Nền tảng data ULead</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tìm kiếm data từ Facebook Group</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Tìm kiếm data từ website đăng ký doanh nghiệp</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
                
                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chuyển UID sang số điện thoại</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>

                <tr>
                    <td className="border-b border-gray-300 px-4 py-2">Chuyển số điện thoại sang UID</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center"></td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border-b border-gray-300 px-4 py-2 text-center">✔️</td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
  );
};

export default PricingCrmDetail;
