"use client";

import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  Card,
  CardHeader,
  CardBody,
  Image,
  CardFooter,
  Button,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import Link from "next/link";
import ContactFormModal from "../ContactFormModal";
import useDisclosure from "@/hooks/shared/useDisclosure";
import PricingCrmDetail from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/PricingCrmDetail";

const PricingCRM = ({ heading }: { heading?: string }) => {
  const { open, isOpen, close } = useDisclosure();
  const crm_package = [
    {
      package: "Basic",
      price: "50.000",
      describe:
        "Dành cho mô hình kinh doanh nhỏ, người bắt đầu kinh doanh hoặc người kinh doanh solo.",
      feature_title: "Bao gồm các tính năng:",
      features: [
        "Danh bạ liên hệ tùy biến",
        "Chiến dịch Callbot gọi tự động",
        "Tổng đài ảo tích hợp AI",
        "Chiến dịch tin nhắn ZNS, SMS",
        "Chiến dịch Email Marketing",
        "Tìm kiếm data khách hàng",
      ],
    },
    {
      prioritize: true,
      package: "Standard",
      price: "100.000",
      describe:
        "Dành cho mô hình kinh doanh chuyên nghiệp, chuyên môn hóa và tự động hóa quy trình.",
      feature_title:
        "Bao gồm toàn bộ các tính năng gói Basic và các tính năng:",
      features: [
        "Danh sách khách hàng động",
        "Chiến dịch gọi điện Telesales",
        "Chat đa kênh Facebook, Zalo",
        "Quy trình tự động hóa",
        "Tích hợp phần mềm bên thứ 3",
        "Quản lý Ticket",
      ],
    },
    {
      prioritize: false,
      package: "Advanced",
      price: "250.000",
      describe:
        "Dành cho mô hình kinh doanh lớn, nhiều kênh bán & cần dịch vụ cao cấp.",
      feature_title:
        "Bao gồm toàn bộ các tính năng gói Standard và các tính năng:",
      features: [
        "Quản lý sản phẩm, đơn hàng",
        "Xây dựng, theo dõi KPI nhân viên",
        "AI phân tích đoạn hội thoại",
        "Báo cáo & Dashboard nâng cao",
        "Dung lượng ghi âm không giới hạn",
        "Miễn phí cài đặt và đào tạo",
      ],
    },
  ];

  return (
    <div className="py-10">
      <h1 className="font-semibold text-3xl text-adahubSecondary text-center mb-14 whitespace-pre-line">
        {heading}
        {!heading && "PricingCRM.tsx"}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10">
        {crm_package.map((item) => {
          return (
            <React.Fragment key={JSON.stringify(item)}>
              <Card
                className={`py-4 bg-white relative ${item?.prioritize == true ? "-top-8 max-h-[700px]" : "max-h-[570px] mb-10"}`}>
                {item?.prioritize && (
                  <>
                    <div className="absolute top-0 bg-adahubSecondary text-white w-full py-2 text-center">
                      Khuyên dùng
                    </div>
                  </>
                )}
                <CardHeader
                  className={`pb-0 ${item?.prioritize == true ? "pt-5" : ""} px-4 flex-col items-start`}>
                  <h5 className="font-bold pb-3">{item.package}</h5>
                  <div className="flex gap-2">
                    <h2 className="text-4xl text-adahubSecondary font-bold">
                      {item.price}
                    </h2>
                    <p className="pt-3">đồng</p>
                  </div>
                  <p className="text-gray-500">/người dùng/tháng</p>
                  <p className="py-2">{item.describe}</p>
                </CardHeader>

                <CardBody>
                  <Button
                    onClick={() => {
                      open();
                    }}
                    className="bg-adahubSecondary w-full"
                    color="primary">
                    Tư vấn
                  </Button>
                </CardBody>

                <CardFooter className={`pt-2 px-4 flex-col items-start`}>
                  <p className="min-h-[68px] pb-3">{item.feature_title}</p>
                  <div className="flex flex-col gap-2 text-adahubSecondary">
                    {item.features.map((feature) => {
                      return (
                        <>
                          <div className="flex items-center gap-2">
                            <CheckCircleOutlined />
                            <p className="text-[13px]">{feature}</p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </CardFooter>
              </Card>
            </React.Fragment>
          );
        })}
      </div>
      <div>
        <Accordion>
          <AccordionItem
            key="1"
            aria-label="Accordion 1"
            title={
              <div className="text-center underline">Xem đầy đủ tính năng</div>
            }>
            <PricingCrmDetail />
          </AccordionItem>
        </Accordion>
      </div>
      <ContactFormModal isOpen={isOpen} open={open} close={close} />
    </div>
  );
};

export default PricingCRM;
